import React from "react"
import { Link } from "gatsby"
import { FooterConfig, FooterData } from "../config/config-data"
import DirectionMap from "/static/assets/map.svg"
import "../styles/footer.css"


const Footer = ({ CustomBottomHeight }) => (
  <div id="footer_1662794943816" className={CustomBottomHeight ? "Footer_Normal_Bottom Footer" : "Footer"}>
    <div id="footer_1662794944996" style={{gap: "var(--Space_Size_Five)"}} className="Flex_Container">
      {FooterConfig.footer_time_part &&
      <div id="footer_1662794958995" className="Footer_Child_Time">
        <div>Saturday - <span className="Footer_Child_Time_Each">{FooterConfig.time_saturday}</span></div>
        <div>Sunday - <span className="Footer_Child_Time_Each">{FooterConfig.time_sunday}</span></div>
        <div>Monday - <span className="Footer_Child_Time_Each">{FooterConfig.time_monday}</span></div>
        <div>Tuesday - <span className="Footer_Child_Time_Each">{FooterConfig.time_tuesday}</span></div>
        <div>Wednesday - <span className="Footer_Child_Time_Each">{FooterConfig.time_wednesday}</span></div>
        <div>Thursday - <span className="Footer_Child_Time_Each">{FooterConfig.time_thursday}</span></div>
        <div>Friday - <span className="Footer_Child_Time_Each">{FooterConfig.time_friday}</span></div>
      </div>
      }
      {FooterConfig.footer_link_part &&
      <div id="footer_1662794956667" className="Footer_Child_Link">
          {FooterData.map(({ footer_label, footer_url_link, footer_page_link }) => (
          footer_label &&
          <div id={"footer_"+footer_label} key={footer_label}>
            {footer_url_link &&
            <a href={footer_url_link} aria-label="Map Location" target="_blank" rel="noopener noreferrer">{footer_label}</a>
            }
            {footer_page_link &&
            <Link exact="true" to={footer_page_link}>{footer_label}</Link>
            }
          </div>
          ))}
      </div>
      }
      {FooterConfig.footer_info_part &&
      <div id="footer_1662794947091" className="Footer_Child_Info">
        {FooterConfig.email_label &&
        <a id="footer_1662794948179" href={`mailto:${FooterConfig.email_url_link}`} aria-label="Email" title="Email Us" target="_blank" rel="noreferrer">
          <div className="Footer_Child_Info_Card">
            <div className="Footer_Child_Info_Logo">
              <svg width="clamp(0px, 6vmin, 3.4vh)" alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"/></svg>
            </div>
            <div className="Footer_Child_Info_Text">
              {FooterConfig.email_label}
            </div>
          </div>
        </a>
        }
        {FooterConfig.phone_label &&
        <a id="footer_1662794950283" href={`tel:${FooterConfig.phone_url_link}`} aria-label="Phone" title="Call Us">
          <div className="Footer_Child_Info_Card">
            <div className="Footer_Child_Info_Logo">
              <svg width="clamp(0px, 6vmin, 3.4vh)" alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/></svg>
            </div>
            <div className="Footer_Child_Info_Text">
              {FooterConfig.phone_label}
            </div>
          </div>
        </a>
        }
        {
        FooterConfig.location_label
        ?
        FooterConfig.location_url_link
        ?
        <a id="footer_1662794952795" href={FooterConfig.location_url_link} aria-label="Map Location" title="Visit Us" target="_blank" rel="noopener noreferrer">
          <div className="Footer_Child_Info_Card">
            <div className="Footer_Child_Info_Logo">
              <svg width="clamp(0px, 5vmin, 2.8vh)" alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
            </div>
            <div className="Footer_Child_Info_Text">
              {FooterConfig.location_label}
            </div>
          </div>
        </a>
        :
        <div id="footer_1662794954484" className="Footer_Child_Info_Card">
          <div className="Footer_Child_Info_Logo">
            <svg width="clamp(0px, 5vmin, 2.8vh)" alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
          </div>
          <div className="Footer_Child_Info_Text">
            {FooterConfig.location_label}
          </div>
        </div>
        :
        ''
        }
      </div>
      }
      {FooterConfig.footer_map_part &&
      <a id="footer_1662794961451" href={FooterConfig.location_url_link} aria-label="Map Location" target="_blank" rel="noopener noreferrer" className="Direction_Map_Container">
        <div>
          <img src={DirectionMap} height="300" width="300" loading="lazy" className="Direction_Map" alt="Direction Map" title="Google Map Location" />
        </div>
        <div className="Direction_Map_Text">OPEN GOOGLE MAPS</div>
      </a>
      }
    </div>
    <hr />
    <div id="footer_1662794963979" className="Brand_Icons">
      {FooterConfig.social_profile_youtube && <a id="footer_1662794965227" href={FooterConfig.social_profile_youtube} aria-label="youtube" title="Youtube" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg></a>}
      {FooterConfig.social_profile_facebook && <a id="footer_1662794966250" href={FooterConfig.social_profile_facebook} aria-label="facebook" title="Facebook" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></a>}
      {FooterConfig.social_profile_instagram && <a id="footer_1662794978561" href={FooterConfig.social_profile_instagram} aria-label="instagram" title="Instagram" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>}
      {FooterConfig.social_profile_pinterest && <a id="footer_1662794980218" href={FooterConfig.social_profile_pinterest} aria-label="pinterest" title="Pinterest" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"/></svg></a>}
      {FooterConfig.social_profile_tiktok && <a id="footer_1662794981994" href={FooterConfig.social_profile_tiktok} aria-label="tiktok" title="Tiktok" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg></a>}
      {FooterConfig.social_profile_twitter && <a id="footer_1662794983403" href={FooterConfig.social_profile_twitter} aria-label="twitter" title="Twitter" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg></a>}
      {FooterConfig.social_profile_yelp && <a id="footer_1662794985234" href={FooterConfig.social_profile_yelp} aria-label="yelp" title="Yelp" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M42.9 240.32l99.62 48.61c19.2 9.4 16.2 37.51-4.5 42.71L30.5 358.45a22.79 22.79 0 0 1-28.21-19.6 197.16 197.16 0 0 1 9-85.32 22.8 22.8 0 0 1 31.61-13.21zm44 239.25a199.45 199.45 0 0 0 79.42 32.11A22.78 22.78 0 0 0 192.94 490l3.9-110.82c.7-21.3-25.5-31.91-39.81-16.1l-74.21 82.4a22.82 22.82 0 0 0 4.09 34.09zm145.34-109.92l58.81 94a22.93 22.93 0 0 0 34 5.5 198.36 198.36 0 0 0 52.71-67.61A23 23 0 0 0 364.17 370l-105.42-34.26c-20.31-6.5-37.81 15.8-26.51 33.91zm148.33-132.23a197.44 197.44 0 0 0-50.41-69.31 22.85 22.85 0 0 0-34 4.4l-62 91.92c-11.9 17.7 4.7 40.61 25.2 34.71L366 268.63a23 23 0 0 0 14.61-31.21zM62.11 30.18a22.86 22.86 0 0 0-9.9 32l104.12 180.44c11.7 20.2 42.61 11.9 42.61-11.4V22.88a22.67 22.67 0 0 0-24.5-22.8 320.37 320.37 0 0 0-112.33 30.1z"/></svg></a>}
      {FooterConfig.social_profile_etsy && <a id="footer_1662794987273" href={FooterConfig.social_profile_etsy} aria-label="etsy" title="Etsy" target="_blank" rel="noopener noreferrer"><svg alt="Brand Icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M384 348c-1.75 10.75-13.75 110-15.5 132-117.879-4.299-219.895-4.743-368.5 0v-25.5c45.457-8.948 60.627-8.019 61-35.25 1.793-72.322 3.524-244.143 0-322-1.029-28.46-12.13-26.765-61-36v-25.5c73.886 2.358 255.933 8.551 362.999-3.75-3.5 38.25-7.75 126.5-7.75 126.5H332C320.947 115.665 313.241 68 277.25 68h-137c-10.25 0-10.75 3.5-10.75 9.75V241.5c58 .5 88.5-2.5 88.5-2.5 29.77-.951 27.56-8.502 40.75-65.251h25.75c-4.407 101.351-3.91 61.829-1.75 160.25H257c-9.155-40.086-9.065-61.045-39.501-61.5 0 0-21.5-2-88-2v139c0 26 14.25 38.25 44.25 38.25H263c63.636 0 66.564-24.996 98.751-99.75H384z"/></svg></a>}
    </div>
    <hr />
    <div id="footer_1662795095470" className="Normal_Text">
      © {new Date().getFullYear()}, {FooterConfig.footer_brand_text}{` `}{FooterConfig.footer_brand_link ? <a id="footer_1662795109397" href={FooterConfig.footer_brand_link} aria-label={FooterConfig.footer_brand_label} title={FooterConfig.footer_brand_label} target="_blank">{FooterConfig.footer_brand_label}</a> : FooterConfig.footer_brand_label}
    </div>
  </div>
);

export default Footer